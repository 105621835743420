import React from 'react';

import cookies from 'data/cookies-policy.json';
import { useCookiesPolicyMarkdown } from 'hooks';

import { Container, Content, MarkdownContent, Title, Updated } from './CookiesPolicy.style';

export function CookiesPolicy() {
    const cookiesMarkdown = useCookiesPolicyMarkdown();

    return (
        <Container>
            <Content>
                <Title>{cookies.title}</Title>
                <Updated>{cookies.updated}</Updated>
                {cookiesMarkdown.length > 0 && (
                    <MarkdownContent dangerouslySetInnerHTML={{ __html: cookiesMarkdown }} />
                )}
            </Content>
        </Container>
    );
}
