import React from 'react';

import { SEO } from 'components/ui';
import { CookiesPolicy } from 'components/cookies';

export default function Cookies() {
    return (
        <>
            <SEO />
            <CookiesPolicy />
        </>
    );
}
