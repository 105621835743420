import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes, Colors } from 'environment';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 12rem;

    @media ${MediaQueries.phone} {
        margin-bottom: 6rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
        padding: 0 2rem;
    }
`;

export const Title = styled.h1`
    margin-bottom: 2rem;
    color: ${Colors.violet};

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 5rem;
        margin-bottom: 1rem;
    }
`;

export const Updated = styled.p`
    color: ${Colors.violet};
`;

export const MarkdownContent = styled.div`
    font-size: 1.8rem;
    line-height: 2.7rem;
    white-space: pre-line;
    width: 100%;
    color: ${Colors.violet};
    text-align: center;

    p {
        max-width: 60rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }

    p:not(:first-child) {
        margin-top: 2rem;
    }

    a {
        color: ${Colors.magenta};
        border-bottom: 0.2rem transparent solid;
        transition: border-color 0.2s;

        &:hover {
            border-color: ${Colors.magenta};
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    table {
        width: 100%;

        & > tbody {
            text-align: center;
        }
    }

    h1,
    h2,
    h3 {
        font-weight: 600;
        max-width: 60rem;
        text-align: left;
        margin-top: 8rem;
        margin-right: auto;
        margin-bottom: 4rem;
        margin-left: auto;
    }

    ul {
        max-width: 60rem;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        list-style-type: none;
    }

    @media ${MediaQueries.tablet} {
        max-width: 30rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 4rem;

        h1,
        h2 {
            font-size: 3.6rem;
            line-height: 5rem;
        }

        h3 {
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 2.9rem;
            margin-bottom: 2rem;
            margin-top: 4rem;
        }
    }
`;
